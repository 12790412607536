import React from "react";

export const FrontPageImage: React.FC = () => {
  const isJlmdecks = typeof window !== "undefined" && window.location.hostname === "jlmdecks.com";

  const imageSrc = isJlmdecks
    ? "https://storage.googleapis.com/henry-glcoud-production.appspot.com/JLM_Mockup_7%20(1).jpg"
    : "https://storage.googleapis.com/henry-glcoud-production.appspot.com/Henry_Mockup_3.jpg";

  return (
    <div className="hidden overflow-hidden rounded-md bg-gray-100 md:block">
      <img className="h-full w-full object-cover" src={imageSrc} alt="Front Page Logo" />
    </div>
  );
};
